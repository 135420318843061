(function () {

    document.addEventListener("DOMContentLoaded", () => {
        const upcomingFilter = document.getElementById('upcoming-filter'),
                typeFilter = document.getElementById('event-type'),
                resultsFeedback = document.getElementById('no-results'),
                eventItems = document.querySelectorAll('.event-results__item');

        // User Feedback
        const updateFeedback = () => {
            let results = 0;
    
            // update results feedback
            eventItems.forEach(function (item) {
                if ( !item.classList.contains('hide') ) {
                    results = results + 1;
                }
            });
    
            // resultsFeedback
            if (results == 0) {
                resultsFeedback.classList.remove('hide');
            } else {
                resultsFeedback.classList.add('hide');
            }
        }

        const resetResults = () => {
            eventItems.forEach(function (item) {
                item.classList.remove('hide');
            });
        }
        
        // Filter By Upcoming
        upcomingFilter.addEventListener('change', function () {
            resetResults();

            if (this.checked && typeFilter.value == 'event-type') {
                // show all upcoming events
                eventItems.forEach(function (item) {
                    if ( item.dataset.upcoming != 'true') {
                        item.classList.add('hide');
                    }
                });

             // show upcoming events that match type selected
            } else if (this.checked && typeFilter.value != 'event-type') {
                eventItems.forEach(function (item) {
                    if ( item.dataset.upcoming != 'true' || !item.dataset.type.includes(typeFilter.value) ) {
                        item.classList.add('hide');
                    }
                });

            // show all by type
            } else if (!this.checked && typeFilter.value != 'event-type') {
                eventItems.forEach(function (item) {
                    if ( !item.dataset.type.includes(typeFilter.value) ) {
                        item.classList.add('hide');
                    }
                });
            }
            
            // show all
            else {
                eventItems.forEach(function (item) {
                    item.classList.remove('hide');
                });
            }

            updateFeedback();
        });

        // Filter By Type
        typeFilter.addEventListener('change', function () {
            resetResults();

            if (upcomingFilter.checked && typeFilter.value == 'event-type') {
                // show all upcoming events
                eventItems.forEach(function (item) {
                    if ( item.dataset.upcoming != 'true') {
                        item.classList.add('hide');
                    }
                });

            // show upcoming events that match type selected
            } else if (upcomingFilter.checked && typeFilter.value != 'event-type') {
                eventItems.forEach(function (item) {
                    if ( item.dataset.upcoming != 'true' || !item.dataset.type.includes(typeFilter.value) ) {
                        item.classList.add('hide');
                    }
                });

            // show all that match type selected
            } else if (!upcomingFilter.checked && typeFilter.value != 'event-type') {
                eventItems.forEach(function (item) {
                    if ( !item.dataset.type.includes(typeFilter.value) ) {
                        item.classList.add('hide');
                    }
                });
            }
            
            // show all
            else {
                eventItems.forEach(function (item) {
                    item.classList.remove('hide');
                });
            }

            updateFeedback();
        });

        const setUpcomingEvents = () => {
            const todayDate = new Date();

            eventItems.forEach(function (item) {
                let itemDateFrom = item.querySelector('.date-from').textContent,
                    dateFrom = new Date(itemDateFrom);

                if ( dateFrom.getTime() > todayDate.getTime() ) {
                    item.setAttribute('data-upcoming', 'true');
                } else {
                    item.setAttribute('data-upcoming', 'false');
                }
            });
       }

        setUpcomingEvents();

    });

}());